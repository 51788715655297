import * as _stimulus2 from "@hotwired/stimulus";
var _stimulus = "default" in _stimulus2 ? _stimulus2.default : _stimulus2;
var exports = {};
(function (e, s) {
  "object" < "u" ? exports = s(_stimulus) : (e = typeof globalThis < "u" ? globalThis : e || self, e.StimulusPasswordVisibility = s(e.Stimulus));
})(exports, function (e) {
  "use strict";

  class s extends e.Controller {
    connect() {
      this.hidden = this.inputTarget.type === "password", this.class = this.hasHiddenClass ? this.hiddenClass : "hidden";
    }
    toggle(t) {
      t.preventDefault(), this.inputTarget.type = this.hidden ? "text" : "password", this.hidden = !this.hidden, this.iconTargets.forEach(i => i.classList.toggle(this.class));
    }
  }
  return s.targets = ["input", "icon"], s.classes = ["hidden"], s;
});
export default exports;